import Collapsible from "../../../components/collapsible/collapsible";
import "./FAQ.scss";

const FAQ = () => {
    return (
        <>
            <div className="faq" id="faq">
                <div className="container">
                    <div className="faq__title">Frequently Asked Questions</div>
                    <div className="faq__description">
                        We have created a FAQ for your convenience. If you still have questions, please feel free
                        to contact us at
                        <a href='mailto:sales@exceltrailers.ca' target='_blank' className='faq__description-link' rel='noreferrer'>
                            sales@exceltrailers.ca.
                        </a>
                    </div>
                    <div className="faq__content">
                        <div className="faq__content-block">
                            <Collapsible label="Cancellation">
                                <div>
                                    You can cancel Office Trailers orders within 48 hours. <br/>
                                    For Flat Deck Trailers and Refrigerators, cancellation is possible within 24
                                    hours. <br/>
                                    However, we strongly encourage you to cancel as early as possible, or even better,
                                    not
                                    to cancel at all.
                                </div>
                            </Collapsible>
                            <Collapsible label="Delivery">
                                <div>
                                    We can deliver the trailer to any location within Alberta at an affordable price, or
                                    you can choose to pick it up from our office in Calgary at a time convenient
                                    for you. <br/>
                                    There is also the option of one-way delivery, or self-pickup on the other end. Any
                                    variations that are convenient for you are possible
                                </div>
                            </Collapsible>
                            <Collapsible label="Shortening the Rental Period">
                                <div>
                                    If you return the trailer before the planned date, we will refund the remaining
                                    amount.
                                </div>
                            </Collapsible>
                            <Collapsible label="How to light propane heater">
                                <div>
                                    Lighting Instructions for Empire Heating System DV210SGXLP <br/>
                                    1. Remove control access panel (front panel). <br/>
                                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                                    2. Turn knob on gas control counterclockwise to "PILOT." <br/>
                                    3. Push in control knob all the way and hold in. After 2-5 minutes light the pilot
                                    with
                                    the Piezo Pilot Ignitor or a match. Continue to hold the control knob in for about
                                    2-3
                                    minutes after the pilot is lit. Release knob, and it will pop back up. Pilot should
                                    remain lit. <br/>
                                    4. Replace pilot access cover. <br/>
                                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                                    5. Turn gas control knob counterclockwise to "ON." <br/>
                                    6. Replace control access panel (front panel). <br/>
                                    CAUTION: Pilot access must be covered after you light it <br/>
                                    <a href="https://youtu.be/L581WCw6c-Q" target="_blank"
                                       rel="noreferrer">Watch this video</a>
                                </div>
                            </Collapsible>
                        </div>
                        <div className="faq__content-block">
                            <Collapsible label="Payment method">
                                <div>
                                    For Office Trailers, we invoice you monthly with 30 days pay period. <br/>
                                    Flat Deck Trailers can be paid online on the website or we will issue an invoice to
                                    you. <br/>
                                    Advance payment is mandatory for Flat Decks. <br/>
                                    We prefer direct payment, however we alco accept Credit Cards or cheques. No cash.
                                </div>
                            </Collapsible>
                            <Collapsible label="Discounts">
                                <div>Discounts are available for long-term trailer rentals and can be discussed with the
                                    managers.
                                </div>
                            </Collapsible>
                            <Collapsible label="Repair, installation, and service">
                                <div>
                                    The rental cost includes installation and repair, as we care about our customers and
                                    aim
                                    to provide the highest quality service, however maintenance like cleaning, furnace
                                    filter replacement and fire exqigusher inspection should be done by lessor. We
                                    reserve
                                    the right to refuse repairs in cases where damages were intentional.
                                </div>
                            </Collapsible>
                            <Collapsible label="If you lost key">
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                <div>In even you lost your key, please call us at 403-465-1277. We'll provide a code
                                    for accessing the black key box on your trailer.
                                </div>
                            </Collapsible>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FAQ;

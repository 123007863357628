import { INotification } from "../../context/toast/toast.context";
import { NotificationItem } from "./notification-item/notification-item";
import "./notification-wrapper.scss";

export interface TNotificationsWrapperProps {
  notifications: INotification[];
  deleteNotification: (id?: string) => void;
}

export function NotificationsWrapper({ notifications, deleteNotification }: TNotificationsWrapperProps) {
  return (
    <div className='notifications-wrapper'>
      {notifications.map((notification) => (
        <NotificationItem
          deleteNotification={deleteNotification}
          key={notification.text + "_" + Math.random() * 100}
          notification={notification}
        />
      ))}
    </div>
  );
}

export default NotificationsWrapper;

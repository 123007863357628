import { memo, MouseEventHandler } from "react";
import "./svg-icon.scss";

export interface SvgIconProps {
  name: string;
  className?: string;
  onClick?: MouseEventHandler;
  color?: string;
}

export function SvgIcon({ name, className = "", onClick, color }: SvgIconProps): JSX.Element {
  const href = `#${name}`;

  return (
    <svg fill={color} onClick={onClick} className={className}>
      <use
        ref={(el: SVGUseElement) => {
          el?.setAttribute("href", href);
          el?.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", href);
        }}
      />
    </svg>
  );
}

export default memo(SvgIcon);

import { TrailersBlockItem } from "./trailer-block-item/trailers-block-item";
import "./trailers-blocks.scss";

export interface TrailersBlocksTrailer {
    img: string,
    title: string,
    link: string,
}

export const TrailersBlocks = () => {
    const trailers: TrailersBlocksTrailer[] = [
        {
            img: "rent/wheeled-office-trailers.png",
            title: "Wheeled Office",
            link: "/office-trailers"
        },
        {
            img: "rent/skidded-office-trailers.png",
            title: "Skidded Office",
            link: "/office-trailers"
        },
        {
            img: "rent/flat-deck-trailers.png",
            title: "Flat Deck",
            link: "/flat-deck-trailers"
        },
        {
            img: "rent/refrigerated-trailers.png",
            title: "Refrigerated",
            link: "/refrigerators"
        },
    ];

    return (
        <div className="trailers-blocks">
            <div className="container">
                <div className="trailers-blocks__wrapper">
                    {trailers.map((trailer, index) => (
                        <TrailersBlockItem key={index} trailer={trailer}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

import "./notification-item.scss";
import SvgIcon from "../../svg-icon/svg-icon";
import { INotification } from "../../../context/toast/toast.context";

export interface NotificationItemProps {
  notification: INotification;
  deleteNotification: (id?: string) => void;
}

export const NotificationItem = ({ notification, deleteNotification }: NotificationItemProps) => {
  const { text, type, id } = notification;

  return (
    <div className={`${type} notification-item`}>
      <div className='first-column'>
        <SvgIcon name='success' className='prefix-icon' />
        <div>{text}</div>
      </div>
      <SvgIcon name='cross-circle' className='close-icon' onClick={() => deleteNotification(id)} />
    </div>
  );
};

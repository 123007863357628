import "./spinner.scss";

interface SpinnerProps {
  color?: "dark" | "light";
  className?: string;
}

export const Spinner = ({ color = "dark", className }: SpinnerProps): JSX.Element => {
  const classes = `spinner__dot ${color}`;

  return (
    <div className={`spinner ${className}`}>
      <div className={classes}></div>
      <div className={classes}></div>
      <div className={classes}></div>
      <div className={classes}></div>
    </div>
  );
};

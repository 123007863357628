import { IToast } from "../../context/toast/toast.context";
import { ToastItem } from "./toast-item/toast-item";
import "./toasts-wrapper.scss";

export interface ToastsWrapperProps {
  toasts: IToast[];
}

export function ToastsWrapper({ toasts }: ToastsWrapperProps) {
  return (
    <div className='toasts-wrapper'>
      {toasts.map((toast) => (
        <ToastItem key={toast.text + "_" + Math.random() * 100} toast={toast} />
      ))}
    </div>
  );
}

export default ToastsWrapper;

import { useCallback, useMemo, useState } from "react";
import storage from "../utils/storage";

export const isAuthenticated = (): boolean | undefined => {
  return !!storage.get("accessToken");
};

export const clearStorage = (): void => {
  Object.keys(localStorage).forEach((key: string): void => {
    if (key !== "excel-trailers_accessToken" && key !== "excel-trailers_refreshToken" && key !== "excel-trailers_personalInfo" ) {
     localStorage.removeItem(key);
    }
  })
};

export const useLocalStorage = <T = unknown>(key: string, initialValue?: unknown): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = storage.get(key);

      return item;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        storage.set(key, JSON.stringify(valueToStore));
      } catch (error) {
        return;
      }
    },
    [key, storedValue],
  );

  return useMemo(() => [storedValue as T, setValue], [setValue, storedValue]);
};

import { lazy } from "react";

// export const HomeTrailers = lazy(() => import("../pages/home/trailers/trailers"));
export const Rent = lazy(() => import("../pages/rent/rent"));
export const RentOfficeTrailers = lazy(() => import("../pages/rent/office-trailers/office-trailers"));
export const RentFlatDeckTrailers = lazy(() => import("../pages/rent/flat-deck-trailers/flat-deck-trailers"));
export const Refrigerators = lazy(() => import("../pages/rent/refrigerators/refrigerators"));
export const Accessories = lazy(() => import("../pages/rent/accessories/accessories"));
export const SaleNewOfficeTrailers = lazy(() => import("../pages/sale/sale-new-trailers/sale-new-trailers"));
export const SaleUsedOfficeTrailers = lazy(() => import("../pages/sale/sale-used-trailers/sale-used-trailers"));
export const OfficeTrailerDetails = lazy(
  () => import("../pages/rent/rent-trailers/office-trailer-details/office-trailer-details"),
);
export const SaleNewTrailerDetails = lazy(
  () => import("../pages/sale/sale-new-trailers/sale-new-trailer-details/sale-new-trailer-details"),
);
export const SaleUsedTrailerDetails = lazy(
  () => import("../pages/sale/sale-used-trailers/sale-used-trailer-details/sale-used-trailer-details"),
);
export const RefrigeratorDetails = lazy(
  () => import("../pages/rent/refrigerators/refrigerator-details/refrigerator-details"),
);
export const BookTrailer = lazy(() => import("../pages/book-trailer/book-trailer"));
export const BookOfficeTrailer = lazy(() => import("../pages/book-office-trailer/book-office-trailer"));
export const BuyTrailer = lazy(() => import("../pages/buy-trailer/buy-trailer"));
export const ContactUs = lazy(() => import("../pages/contact-us/contact-us"));
export const PrivacyPolicy = lazy(() => import("../pages/privacy-policy/privacy-policy"));
export const SendCreditApplication = lazy(() => import("../pages/send-credit-application/send-credit-application"));
export const SuccessPage = lazy(() => import("../pages/success-page/success-page"));
export const BlogPage = lazy(() => import("../pages/blog/blog"));
export const ArticleHowToHookUpAnOfficeTrailerToTheVehiclePage = lazy(() => import("../pages/blog/articles/how-to-hook-up-an-office-trailer-to-the-vehicle"));
export const ArticleWhatIsAnOfficeTrailerPage = lazy(() => import("../pages/blog/articles/what_is_an_office-trailer"));
export const ArticleExploringTheVariedTerrainOfTrailersPage = lazy(() => import("../pages/blog/articles/exploring-the-varied-terrain-of-trailers"));
export const ArticleOptimizingTheWorkspaceInOfficeTrailersForBuildersPage = lazy(() => import("../pages/blog/articles/optimizing-the-workspace-in-office-trailers-for-builders"));
export const ArticleFlatDeckRentOrBuyPage = lazy(() => import("../pages/blog/articles/flat-deck-rent-or-buy"));
export const SignInPage = lazy(() => import("../pages/auth/sign-in/sign-in"));
export const SignUpPage = lazy(() => import("../pages/auth/sign-up/sign-up"));
export const ForgotPasswordPage = lazy(() => import("../pages/auth/forgot-password/forgot-password"));
export const RestoreCodePage = lazy(() => import("../pages/auth/restore-code/restore-code"));
export const NewPasswordPage = lazy(() => import("../pages/auth/new-password/new-password"));
export const ProfilePage = lazy(() => import("../pages/profile/profile"));
export const PersonalInfoPage = lazy(() => import("../pages/profile/personal-info/personal-info"));
export const OrdersPage = lazy(() => import("../pages/profile/orders/orders"));
export const CouponsPage = lazy(() => import("../pages/profile/coupons/coupons"));
export const AboutUsPage = lazy(() => import("../pages/about-us/about-us"));

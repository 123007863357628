interface Props {
  width: number;
  height: number;
}

export const Logo = ({ width, height }: Props): JSX.Element => {
  return (
    <img
      className='logo'
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/assets/images/white-logo.svg`}
      alt='Logo'
    />
  );
};

import "./layout.scss";

import { WithChildren } from "../../interfaces";
import { Suspense } from "react";
import { Spinner } from "../spinner/spinner";
import { Header } from "./header/header";
import { Footer } from "./footer/footer";

export const Layout = ({ children }: WithChildren): JSX.Element => {
  return (
    <div className='layout'>
      <div className='layout__header'>
        <Header />
      </div>
      <Suspense
        fallback={
          <div>
            <Spinner />
          </div>
        }
      >
        <div className='layout__content'>{children}</div>
      </Suspense>
      <div className='layout__footer'>
        <Footer />
      </div>
    </div>
  );
};

interface Props {
  name: string;
  className?: string;
  onClick?: () => void;
  alt?: string;
}

export const ImageLink = ({ name, className }: Props): JSX.Element => {
  return (
    <img
      className={`${className} logo`}
      loading='lazy'
      src={`${process.env.PUBLIC_URL}/assets/images/${name}`}
      alt={name}
      title={name}
    />
  );
};

export const ImageURL = ({ name, className, onClick, alt = "Trailer" }: Props): JSX.Element => {
  return <img className={`${className}`} loading='lazy' src={`${name}`} onClick={onClick} alt={alt} title={alt} />;
};

export const ImageTrailerLink = ({ name, className }: Props): JSX.Element => {
  return (
    <img
      className={`${className}`}
      src={`${process.env.PUBLIC_URL}/assets/images/trailers/${name}`}
      alt={name}
      title={name}
    />
  );
};

export const ImageAccessoryLink = ({ name, className }: Props): JSX.Element => {
  return (
    <img
      className={`${className}`}
      loading='lazy'
      src={`${process.env.PUBLIC_URL}/assets/images/accessories/${name}`}
      alt='Accessory'
    />
  );
};

import { IToast } from "../../../context/toast/toast.context";
import "./toast-item.scss";

export interface ToastItemProps {
  toast: IToast;
}

export const ToastItem = ({ toast }: ToastItemProps) => {
  const { text, type } = toast;

  return <div className={`${type} toast`}>{text}</div>;
};

import { ReactNode, useState } from "react";
import "./collapsible.scss"
import { useCollapse } from "react-collapsed";

interface CollapsibleProps {
    label: string;
    children: ReactNode;
}

const Collapsible = (props: CollapsibleProps) => {
    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

    return (
        <div className={`collapsible-block ${isExpanded ? "active" : ""}`}>
            <div  {...getToggleProps({
                onClick: () => setExpanded((prevExpanded) => !prevExpanded),
            })}>
                <div className='collapsible-block__title'>
                    {props.label}
                    <svg className={`collapsible-block__icon ${isExpanded ? "close" : "open"}`} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 1V13" stroke="#667085" strokeWidth="1.32353" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13 7H1" stroke="#667085" strokeWidth="1.32353" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>
           <div {...getCollapseProps()}>
               <div className="collapsible-block__text">
                   <div className="content">{props.children}</div>
               </div>
           </div>

        </div>
    );
};
export default Collapsible;

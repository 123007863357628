import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SvgIcon from "../../svg-icon/svg-icon";
import { Logo } from "../../ui/logo";
import "./header.scss";
import { MenuItems } from "./menu-items/menu-items";
import {useAuth} from "../../../context/auth-provider";

export const Header = (): JSX.Element => {
  const [active, setActive] = useState(false);
  const auth = useAuth();
  const links = [
    {
      label: "COMPANY",
      link: "/",
      submenu: [
        {
          title: "About us",
          url: "/about-us",
        },
        {
          title: "Blog",
          url: "/blog",
        },
      ],
      show: true
    },
    {
      label: "RENT",
      link: "/rent",
      submenu: [
        {
          title: "Office Trailers",
          url: "/office-trailers",
        },
        {
          title: "Flat Deck Trailers",
          url: "/flat-deck-trailers",
        },
        {
          title: "Refrigerators",
          url: "/refrigerators",
        },
        {
          title: "Accessories",
          url: "/accessories",
        },
      ],
      show: true
    },
    {
      label: "SALE",
      link: "/sale-new-trailers",
      submenu: [
        {
          title: "New Trailers",
          url: "/sale-new-trailers",
        },
        {
          title: "Used Trailers",
          url: "/sale-used-trailers",
        },
      ],
      show: true
    },
    { label: "FAQ", link: "/#faq", show: true},
    // { label: "CONTACT US", link: "/contact-us", class: "menu-items__button form-button--primary-blue", show: true },
    { label: "CONTACT US", link: "/contact-us", show: true },
    { label: "SIGN IN", link: "/sign-in", class: "menu-items__button form-button--primary-blue", show: !auth?.isAuthorized() },
    { label: "My Account", link: "/profile", class: "menu-items__button form-button--border", show: auth?.isAuthorized() },
  ];
  const location = useLocation();

  const onActivateMenu = (): void => {
    setActive(!active);
  };

  useEffect(() => {
    setActive(false);
  }, [location.pathname]);

  useEffect(()=> {
    if (location.hash) {
      const elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        setTimeout(() => {
          elem.scrollIntoView({block: "start", behavior: "smooth", inline: "start"});
          setActive(false);
        }, 0);

      }
    } else {
      window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
  }, [location.hash])

  return (
    <div className='header'>
      <div className='container'>
        <div className='header__container'>
          <div className='header__contact-block'>
            <div className='header__logo'>
              <Link to='/'>
                <Logo width={70} height={70} />
              </Link>
            </div>
            <div className='header__contact-info'>
              <div className='header__phone'>
                <a href='tel:4034651277' target='_blank' className='header__link' rel='noreferrer'>
                  <SvgIcon name='phone' className='header__icon-svg' />
                  403 465 1277
                </a>
              </div>
              <div className='header__mail'>
                <a href='mailto:sales@exceltrailers.ca' target='_blank' className='header__link' rel='noreferrer'>
                  <SvgIcon name='mail' className='header__icon-svg' />
                  sales@exceltrailers.ca
                </a>
              </div>
            </div>
          </div>
          <div className='header__mobile-menu'>
            <SvgIcon name='hamburger' className='header__hamburger-icon' onClick={onActivateMenu} />
          </div>
          <div
            className={`header__mobile-navigation ${
              active ? "header__mobile-navigation--open" : "header__mobile-navigation--close"
            }`}
          >
            <div className='header__mobile-action'>
              <div className='header__mobile-action__text'>Menu</div>
              <SvgIcon name='cross-circle' className='header__hamburger-icon' onClick={onActivateMenu} />
            </div>
            <div className='header__mobile-block'>
              <div className='header__mobile-links'>
                <ul className='header__menu header__menu--mobile'>
                  {links?.map((link) => (
                    <MenuItems key={link.label} items={link} />
                  ))}
                </ul>
              </div>
              <div className='header__mobile-contact-info'>
                <div className='header__phone'>
                  <a href='tel:4034651277' target='_blank' className='header__link' rel='noreferrer'>
                    <SvgIcon name='phone' className='header__icon-svg' />
                    403 465 1277
                  </a>
                </div>
                <div className='header__mail header__mail--mobile'>
                  <a href='mailto:sales@exceltrailers.ca' target='_blank' className='header__link' rel='noreferrer'>
                    <SvgIcon name='mail' className='header__icon-svg' />
                    sales@exceltrailers.ca
                  </a>
                </div>
              </div>
              <div className='header__mobile-socials'>
                <SvgIcon name='facebook' className='header__social-icon' />
                <SvgIcon name='linkedin' className='header__social-icon' />
                <SvgIcon name='instagram' className='header__social-icon' />
              </div>
            </div>
          </div>
          <div className='header__navigation'>
            <ul className='header__menu'>
              {links?.map((link) => (
                  <MenuItems key={link.label} items={link} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Helmet } from "react-helmet-async";

interface SeoProps{
    title: string;
    description: string;
}

export default function SEO({title, description}: SeoProps) {
    return (
        <Helmet>
            { /* Standard metadata tags */ }
            <title>{title}</title>
            <meta name='title' content={title} />
            <meta name='description' content={description} />
            { /* End standard metadata tags */ }
            { /* Facebook tags */ }
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="./assets/images/black-logo.svg" />
            { /* End Facebook tags */ }
            { /* Twitter tags */ }
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:url" content="https://exceltrailers.ca" />
            <meta name="twitter:image" content="./assets/images/black-logo.svg" />
            { /* End Twitter tags */ }

            <link rel="canonical" href={window.location.href} />
        </Helmet>
    )
}

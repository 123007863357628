import { useContext, createContext, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../utils/http-client";
import {
    AuthContextType,
    Login,
    LoginBody,
    LoginData, Profile, ProfileEmail,
    SignInGoogle,
    SignUp,
    SignUpData,
    SignUpTrailerData
} from "../interfaces";
import { AxiosResponse } from "axios";
import { ToastContext } from "./toast/toast.context";
import { clearStorage, useLocalStorage } from "../hooks/use-local-storage.hook";

const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({children}: {children?: ReactNode}) {
    const [accessToken, setAccessToken] = useLocalStorage<string>("accessToken", "");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [refreshToken, setRefreshToken] = useLocalStorage<string>("refreshToken", "");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { addToast } = useContext(ToastContext);

    const loginAction = (data: LoginData, ) => {
        setIsLoading(true);
        const body: LoginBody = {
            authProvider: "INTERNAL",
            credentials: {
                id: data.email,
                secret: data.password
            }
        }
        login(body);
    };

    const login = (body: LoginBody) => {
        httpClient
            .post<Login>("client/login", body)
            .then((response: AxiosResponse) => response.data)
            .then((res: Login) => {
                if (res) {
                    setAccessToken(res.accessToken);
                    setRefreshToken(res.refreshToken);
                    fetchPersonalInfo();
                    navigate("/");
                }
                setIsLoading(false);
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: Error | any) => {
                setIsLoading(false);
                addToast({
                    text: error?.response?.data?.message || error.message,
                    type: "error",
                });
            });
    }

    const signUpAction = (data: SignUpData) => {
        setIsLoading(true);
        const body = {
            name: data.name,
            email: data.email,
            phoneNumber: data.phoneNumber,
            password: data.password
        };

        httpClient
            .post<SignUp>("client/signup", body)
            .then((response: AxiosResponse) => response.data)
            .then((res: SignUp) => {
                if (res) {
                    setAccessToken(res.accessToken);
                    setRefreshToken(res.refreshToken);
                    fetchPersonalInfo();
                    navigate("/");
                }
                setIsLoading(false);
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: Error | any) => {
                console.log("error", error);
                setIsLoading(false);
                addToast({
                    text: error?.response?.data?.message || error.message,
                    type: "error",
                });
            });
    }

    const signUpByOrder = (data: SignUpTrailerData) => {
        setIsLoading(true);
        const body = {
            trailerOrderId: data.trailerOrderId,
            password: data.password,
            subscribePromotions: data.subscribePromotions
        };

        httpClient
            .post<SignUp>("client/signup-by-order", body)
            .then((response: AxiosResponse) => response.data)
            .then((res: SignUp) => {
                if (res) {
                    setAccessToken(res.accessToken);
                    setRefreshToken(res.refreshToken);
                    navigate("/");
                }
                setIsLoading(false);
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: Error | any) => {
                setIsLoading(false);
                addToast({
                    text: error?.response?.data?.message || error.message,
                    type: "error",
                });
            });
    }

    const signInByGoogle = (data: SignInGoogle) => {
        if (data && data.credential) {
            const body: LoginBody = {
                authProvider: "GOOGLE",
                credentials: {
                    id: data.credential
                }
            }
            login(body)
        }
    }

    const changeEmail = (data: ProfileEmail) => {
        httpClient
            .patch<Profile>("client/clients/email", data)
            .then((response: AxiosResponse) => response.data)
            .then((res) => {
                // data is tokens
                console.log("data after change email", res);
                if (res) {
                    setAccessToken(res.accessToken);
                    setRefreshToken(res.refreshToken);
                    addToast({
                        text: "Email updated successfully",
                        type: "success",
                    });
                }
            })
            .catch(() => {
                addToast({
                    text: "Failed to update email",
                    type: "error",
                });
            })
    }

    const fetchPersonalInfo = () => {
        httpClient
            .get<Profile>("client/clients/me")
            .then((response: AxiosResponse) => response?.data)
            .then((profile: Profile) => {
                localStorage.setItem("excel-trailers_personalInfo", JSON.stringify(profile));
            })
    }

    const logOut = () => {
        setAccessToken("");
        setRefreshToken("");
        localStorage.removeItem("excel-trailers_accessToken");
        localStorage.removeItem("excel-trailers_refreshToken");
        localStorage.removeItem("excel-trailers_personalInfo");
        clearStorage();
        navigate("/");
        window.location.reload();
    }

    const isAuthorized = (): boolean => {
        return !!accessToken;
    }

    const value: AuthContextType = {
        isLoading,
        isAuthorized,
        loginAction,
        signUpAction,
        signUpByOrder,
        signInByGoogle,
        logOut,
        changeEmail,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};

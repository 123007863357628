import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { environment } from "../environments/environment";
import storage from "./storage";

const defaultConfiguration: AxiosRequestConfig = {
    baseURL: environment.apiBaseUrl,
    responseType: "json",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
};

const axiosInstance: AxiosInstance = axios.create(defaultConfiguration);

let isRefreshTokenFetching = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let failedApis: any[] = [];

axiosInstance.interceptors.request.use(function (config) {
    const token = storage.get("accessToken");
    if (config && config.headers) {
        config.headers.Authorization = token ? `Bearer ${token}` : "";
    }
    return config;
})

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        try {
            const originalRequest = error.config;

            if (((error.response.status === 401 || error.response.status === 403)
                && error.response.data.status !== "UNAUTHORIZED") && !originalRequest?._retry) {
                originalRequest._retry = true;

                if (!isRefreshTokenFetching) {
                    failedApis.push(originalRequest);
                    isRefreshTokenFetching = true;

                    const refresh = storage.get("refreshToken");
                    const response = await httpClient.post("client/refresh", {
                        authProvider: "INTERNAL",
                        refreshInfo: {
                            refreshToken: refresh,
                        }
                    }).then((response: AxiosResponse) => response.data);
                    if (response.errorCode) {
                        throw response;
                    }

                    if (response) {
                        storage.set("accessToken", response.accessToken);
                        storage.set("refreshToken", response.refreshToken);
                        // calling all the failed apis
                        if (failedApis.length > 0) {
                            const failedApisCall = failedApis.map(api => axiosInstance(api));
                            await Promise.all(failedApisCall);
                            isRefreshTokenFetching = false;
                            failedApis = [];
                        }
                    }

                    return axiosInstance(originalRequest);

                } else {
                    failedApis.push(originalRequest);
                }
            }

            return Promise.reject(error);
        } catch (err) {
            storage.clear();
            // location.reload();
            return Promise.reject(error);
        }
    }
)

const _get = <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return axiosInstance.get<T>(url, config);
};

const _post = <T>(url: string, body: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return axiosInstance.post<T>(url, body, config);
};

const _put = <T>(url: string, body: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return axiosInstance.put<T>(url, body, config);
};

const _patch = <T>(url: string, body: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return axiosInstance.patch<T>(url, body, config);
};

const _delete = <T>(url: string, id: number, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return axiosInstance.delete(`${url}/${id}`, config);
};

export const httpClient = {
    get: _get,
    post: _post,
    put: _put,
    patch: _patch,
    delete: _delete,
};

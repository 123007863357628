import SvgIcon from "../../../components/svg-icon/svg-icon";
import "./why-choose.scss";

export const HomeWhyChoose = (): JSX.Element => {
  const benefits = [
    {
      icon: "calendar-check-alt",
      name: "We offer weekly, monthly or yearly rental terms",
    },
    {
      icon: "tags",
      name: "With access to specialized transportation, we offer competitive delivery prices",
    },
    {
      icon: "file-check",
      name: "We specialize in small to medium size projects",
    },
    {
      icon: "user-heart",
      name: "Our sales team is available to provide you with quote in a timely manner",
    },
    {
      icon: "star-shooting-horizontal",
      name: "Our modular units are clean, affordable, and well maintained",
    },
  ];
  return (
    <div className='why-choose'>
      <div className='container'>
        <div className='why-choose__title'>WHY CHOOSE EXCEL TRAILERS</div>
        <div className='why-choose__benefits'>
          {benefits?.map((benefit, index) => (
            <div key={benefit.name} className='why-choose__item'>
              <SvgIcon
                name={benefit.icon}
                className={`why-choose__icon-svg ${index === 1 && "why-choose__icon-svg--second-child"} ${
                  index === 3 && "why-choose__icon-svg--third-child"
                }`}
              />
              <p className='why-choose__text'>{benefit.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import { Trailer } from "../interfaces";

export const homeOfficeTrailers: Trailer[] = [
  {
    id: "0",
    name: "8’x16’ Wheeled Office Trailer",
    sizeName: "8’x16’ Wheeled Office Trailer",
    cost: "$ 700 per month",
    description:
      "This office trailer rental option is perfect for small projects with a crew of about 4-6 workers and one to two foremen, safety officers, etc.. It will be an ideal office for construction, repair companies, as well as for private use. This unit can fit into one parking lot. Unit comes with electrical/propane heater and AC. We will deliver our office trailer to any location within Alberta.",
    image: {
      contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x16_wheeled/traleir8x16.webp`,
      contentType: "webp",
      downloadPath: "",
      id: "0",
    },
    dimensions: "OFFICE_8_X_16",
    images: [
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x16_wheeled/traleir8x16.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "0",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x16_wheeled/traleir8x16_2.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "1",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x16_wheeled/traleir8x16_3.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "2",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x16_wheeled/traleir8x16_4.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "3",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x16_wheeled/traleir8x16_5.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "4",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x16_wheeled/traleir8x16_6.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "5",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x16_wheeled/traleir8x16_7.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "6",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x16_wheeled/traleir8x16_8.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "7",
      },
    ],
    size: "128",
    power: "110/220",
    weight: "4000",
    hitch: "2 5/16",
    type: "Wheeled",
  },
  {
    id: "1",
    name: "8’x20’ Wheeled Office Trailer",
    sizeName: "8’x20’ Wheeled Office Trailer",
    cost: "$ 750 per month",
    dimensions: "OFFICE_8_X_20",
    description:
      "If you are looking for office trailer rental in Calgary and surrounding areas for a small project, this option for 6-8 people and one to two supervisors or safety officers will be excellent for you. This small office trailer can be beneficial for a team of electricians, repair technicians, and construction workers. Unit comes with electrical/propane heater and AC. One parking space is sufficient for accommodation.",
    image: {
      contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x20_wheeled/traleir8x20.webp`,
      contentType: "webp",
      downloadPath: "",
      id: "0",
    },
    images: [
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x20_wheeled/traleir8x20.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "0",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x20_wheeled/traleir8x20_2.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "1",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x20_wheeled/traleir8x20_3.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "2",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x20_wheeled/traleir8x20_4.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "3",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x20_wheeled/traleir8x20_5.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "4",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x20_wheeled/traleir8x20_6.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "5",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x20_wheeled/traleir8x20_7.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "6",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x20_wheeled/traleir8x20_8.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "7",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x20_wheeled/traleir8x20_9.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "8",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x20_wheeled/traleir8x20_10.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "9",
      },
    ],
    size: "160",
    power: "110/220",
    weight: "5000",
    hitch: "2 5/16",
    type: "Wheeled",
  },
  {
    id: "2",
    name: "8’x24’ Wheeled Office Trailer",
    sizeName: "8’x24’ Wheeled Office Trailer",
    cost: "$ 800 per month",
    dimensions: "OFFICE_8_X_24",
    description:
      "This office trailer rental option is tailored for small projects, accommodating a crew of around 8-12 workers and one to two foremen, along with safety officers, if needed. This office trailer serves as an excellent office solution for construction, repair companies, and even for personal use. Unit comes with electrical/propane heater and AC. With its compact size, it can easily fit into a single parking space. We offer convenient delivery services for our office trailer to any location within Alberta.",
    image: {
      contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x24_wheeled/traleir8x24.webp`,
      contentType: "webp",
      downloadPath: "",
      id: "0",
    },
    images: [
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x24_wheeled/traleir8x24.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "0",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x24_wheeled/traleir8x24_2.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "1",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x24_wheeled/traleir8x24_3.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "2",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x24_wheeled/traleir8x24_4.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "3",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x24_wheeled/traleir8x24_5.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "4",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x24_wheeled/traleir8x24_6.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "5",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x24_wheeled/traleir8x24_7.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "6",
      },
    ],
    size: "192",
    power: "110/220",
    weight: "6000",
    hitch: "2 5/16",
    type: "Wheeled",
  },
  {
    id: "3",
    name: "8’x26’ Office Trailer with toilet",
    sizeName: "8’x26’ Office Trailer with toilet",
    dimensions: "OFFICE_8_X_26",
    cost: "$ 1000 per month",
    description:
      "This Executive Wheeled Office Trailer has Air Conditioning, propane and electric heat, toilet, fridge microwave, satellite ready portable office unit. Perfect workspace for foremen, safety officers, geologist, etc. Also this office trailer can easily fit into one parking lot. It can completely replace a comfortable small office of an improved level.",
    image: {
      contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x26_wheeled/traleir8x26.webp`,
      contentType: "webp",
      downloadPath: "",
      id: "0",
    },
    images: [
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x26_wheeled/traleir8x26.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "0",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x26_wheeled/traleir8x26_2.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "1",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x26_wheeled/traleir8x26_3.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "2",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x26_wheeled/traleir8x26_4.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "3",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x26_wheeled/traleir8x26_5.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "4",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x26_wheeled/traleir8x26_6.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "5",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/8x26_wheeled/traleir8x26_7.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "6",
      },
    ],
    prices: [
      {
        type: "per day",
        price: 50,
      },
      {
        type: "per week",
        price: 350,
      },
      {
        type: "per month",
        price: 1000,
      },
    ],
    size: "200",
    power: "220V",
    weight: "6500",
    hitch: "2 5/16",
    type: "Wheeled",
  },
  {
    id: "4",
    name: "10’x24’ Skidded Office Trailer",
    sizeName: "10’x24’ Skidded Office Trailer",
    dimensions: "OFFICE_10_X_24",
    cost: "$ 800 per month",
    description:
      "This portable office trailer for rent is great when you are working on a  project with a crew of about 8-10 workers and one to two foremen, safety officers, etc. Due to its dimensions, this office trailer can fit in a small area such as a high riser construction, residential area. This is a great option for office trailer rental in Alberta if you are a construction, repair, or geologist company.",
    image: {
      contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/10x24_skidded/traleir10x24.webp`,
      contentType: "webp",
      downloadPath: "",
      id: "0",
    },
    images: [
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/10x24_skidded/traleir10x24.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "0",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/10x24_skidded/traleir10x24_2.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "1",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/10x24_skidded/traleir10x24_3.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "2",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/10x24_skidded/traleir10x24_4.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "3",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/10x24_skidded/traleir10x24_5.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "4",
      },
    ],
    size: "240",
    power: "110/220",
    weight: "10500",
    hitch: "N/A",
    type: "Skidded",
  },
  {
    id: "5",
    name: "12’x40’ Skidded Office Trailer",
    sizeName: "12’x40’ Skidded Office Trailer",
    dimensions: "OFFICE_12_X_40",
    cost: "$ 1100 per month",
    description:
      "A two-room portable office trailer for rent. It allows you to divide workspaces into zones or separate a lunch area. It's ideal for larger crews. Units comes with Propane heater and AC. Additionally, you can get any necessary equipment, including furniture, refrigerators, and water coolers. We can also transport this office trailer to your location in Alberta.",
    image: {
      contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x40_skidded/traleir12x40.webp`,
      contentType: "webp",
      downloadPath: "",
      id: "0",
    },
    images: [
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x40_skidded/traleir12x40.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "0",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x40_skidded/traleir12x40_2.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "2",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x40_skidded/traleir12x40_3.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "3",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x40_skidded/traleir12x40_4.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "4",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x40_skidded/traleir12x40_5.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "5",
      },
    ],
    size: "480",
    power: "110/220",
    weight: "15000",
    hitch: "N/A",
    type: "Skidded",
  },
  {
    id: "6",
    name: "12’x60’ Skidded Office Trailer",
    sizeName: "12’x60’ Skidded Office Trailer",
    dimensions: "OFFICE_12_X_60",
    cost: "$ 1200 per month",
    description:
      "The three-room office trailer is one of the most popular options. It allows you to separately designate a meeting area or a lunch zone. It's perfect choice for large crews. Units comes with Propane heater and AC. Additionally, you can rent any necessary equipment, including furniture, refrigerators, and water coolers. We can also transport this trailer to your location within Alberta.",
    image: {
      contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x60_skidded/traleir12x60.webp`,
      contentType: "webp",
      downloadPath: "",
      id: "0",
    },
    images: [
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x60_skidded/traleir12x60.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "0",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x60_skidded/traleir12x60_2.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "1",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x60_skidded/traleir12x60_3.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "2",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x60_skidded/traleir12x60_4.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "3",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x60_skidded/traleir12x60_5.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "4",
      },
      {
        contentPath: `${process.env.PUBLIC_URL}/assets/images/trailers/12x60_skidded/traleir12x60_6.webp`,
        contentType: "webp",
        downloadPath: "",
        id: "5",
      },
    ],
    size: "720",
    power: "110/220",
    weight: "23000",
    hitch: "N/A",
    type: "Skidded",
  },
];

import { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import NotificationsWrapper from "../../components/notification-wrapper/notification-wrapper";
import ToastsWrapper from "../../components/toasts-wrapper/toasts-wrapper";

export interface IToast {
  text: string;
  type: "success" | "warning" | "error";
}

interface ToastContextProps {
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ToastContext = createContext<any>(null);

export interface INotification {
  id?: string;
  text: string;
  type: string;
}

const ToastContextProvider = ({ children }: ToastContextProps) => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => {
        setToasts((toasts) => toasts.slice(1));
      }, 8000);

      return () => clearTimeout(timer);
    }
  }, [toasts]);

  useEffect(() => {
    const baseTimeout = 8000;
    const timeoutTime = baseTimeout / notifications.length;
    if (notifications.length > 0) {
      const timer2 = setTimeout(() => {
        setNotifications((notifications) => notifications.slice(1));
      }, timeoutTime);
      return () => clearTimeout(timer2);
    }
  }, [notifications]);

  const addToast = useCallback(
    (toast: IToast) => {
      setToasts((toasts) => [...toasts, toast]);
    },
    [setToasts],
  );

  const addNotification = useCallback(
    (notification: INotification) => {
      setNotifications((notifications) => {
        if (notifications?.pop()?.text === notification?.text) {
          return notifications;
        }
        return [...notifications, { ...notification, id: Date.now().toLocaleString() }];
      });
    },
    [setNotifications],
  );

  const deleteNotification = useCallback(
    (id?: string) => {
      setNotifications((notifications) => notifications.filter((n) => n.id !== id));
    },
    [setNotifications],
  );

  return (
    <ToastContext.Provider value={{ addToast, addNotification }}>
      {children}
      <NotificationsWrapper deleteNotification={deleteNotification} notifications={notifications} />
      <ToastsWrapper toasts={toasts} />
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastContextProvider };

/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "../../../dropdown/dropdown";
import { SvgIcon } from "../../../svg-icon/svg-icon";
import "./menu-items.scss";

interface MenuItemsProps {
  items: {
    label: string;
    link: string;
    submenu?: { title: string; url: string }[],
    class?: string,
    show?: boolean
  };
}

export const MenuItems = ({ items }: MenuItemsProps) => {
  const [dropdown, setDropdown] = useState(false);
  const ref: RefObject<HTMLLIElement | null | undefined> | any = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const handler = (event: any) => {
      if (dropdown && ref?.current && !ref?.current?.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  });

  const onMouseEnter = () => {
    window.innerWidth > 1199 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 1199 && setDropdown(false);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  const handleMenuClick = () => {
    if (window.innerWidth < 1200) {
      setTimeout(() => {
        setDropdown((prev) => !prev);
      }, 0);
    } else {
      if (items.link) {
        navigate(items.link);
      } else {
        setDropdown((prev) => !prev);
      }
    }
  }

  return (
      <>
        {items.show && <li
            className={"menu-items"}
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={closeDropdown}
        >
          {items?.submenu !== undefined ? (
              <>
              <span className='menu-items__link' onClick={() => {
                handleMenuClick();
              }}>
                {items.label}
                <SvgIcon name='caret-down' className='menu-items__arrow' />
              </span>
                <Dropdown submenus={items.submenu} dropdown={dropdown} />
              </>
          ) : (
              <Link to={items.link} className={`menu-items__link ${items.class ? items.class : ""}`}>
                {items.label}
              </Link>
          )}
        </li>}
      </>
  );
};

import "./button.scss";

import React from "react";
import SvgIcon from "../svg-icon/svg-icon";

interface ButtonProps {
  children?: React.ReactNode;
  type?: "submit" | "reset" | "button";
  color?:
    | "primary"
    | "disabled"
    | "light-grey"
    | "white"
    | "light-violet"
    | "link"
    | "transparent"
    | "main"
    | "secondary"
    | "direction"
    | "primary-blue";
  name?: string;
  className?: string;
  inline?: boolean;
  loading?: boolean;
  disabled?: boolean;
  prefImgPosition?: "left" | "right";
  onClick?: React.MouseEventHandler;
  prefImgName?: string;
}

export function Button({
  children,
  type,
  color = "primary",
  name,
  className,
  inline,
  loading,
  disabled,
  prefImgName,
  prefImgPosition,
  onClick,
}: ButtonProps): JSX.Element {
  const rootClassNames = `form-button ${className} form-button--${color} ${inline && "form-button--inline"} ${
    loading && "form-button--loading"
  } ${disabled && "form-button--disabled"}`;

  return (
    <button type={type} name={name} className={rootClassNames} disabled={disabled || loading} onClick={onClick}>
      {prefImgName && (prefImgPosition === "left" || !prefImgPosition) && (
        <SvgIcon name={prefImgName} className='prefImg' />
      )}

      <div className='form-button__inner'>{children}</div>

      {prefImgName && prefImgPosition === "right" && <SvgIcon name={prefImgName} className='prefImg prefImg--right' />}

      {loading && <span className='form-button__spinner' />}
    </button>
  );
}

import { Link } from "react-router-dom";
import "./dropdown.scss";

interface DropdownProps {
  submenus: { title: string; url: string }[];
  dropdown: boolean;
}

export const Dropdown = ({ submenus, dropdown }: DropdownProps) => {
  return (
    <ul className={`dropdown ${dropdown ? "dropdown--show" : ""}`}>
      {submenus.map((submenu) => (
        <li key={submenu.title} className='dropdown__menu-items'>
          <Link to={submenu.url} className='dropdown__link'>
            {submenu.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};
